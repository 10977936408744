import { z } from "zod";

export enum LeaverState {
  CREATED = "CREATED",
  ACTIONED = "ACTIONED",
}

const systemSchema = z.object({
  systemId: z.number().int().positive(),
  name: z.string(),
});

const leaverSchema = z.object({
  id: z.number().int().positive(),
  username: z.string(),
  state: z.nativeEnum(LeaverState),
  systems: z.array(systemSchema),
  terminationDate: z.coerce.date(),
  timestamp: z.coerce.date(),
});

export type Leaver = z.infer<typeof leaverSchema>;

export const newLeaver = (data: unknown): Leaver => leaverSchema.parse(data);
