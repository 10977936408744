import {
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { useLocation } from "wouter";

import { useAdjustUserAccessReviewMutation } from "src/queries";

interface IProps {
  reviewId: number;
}

const AdjustUserAccessReview = ({ reviewId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: adjust, isPending } =
    useAdjustUserAccessReviewMutation(reviewId);

  const onClick = async () => {
    try {
      await adjust();
      addToast("Review adjusted", "success");
      setLocation(`/user-access-reviews/${reviewId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Adjust review" />
      <Card>
        <CardContent>
          Confirm the review has been adjusted, and thereby allow the final UAL.
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Adjust
          </LoadingButton>
          <FormLinks
            links={[{ label: "Back", to: `/user-access-reviews/${reviewId}/` }]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default AdjustUserAccessReview;
