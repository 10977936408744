import { Title } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { LeaverState } from "src/models";
import Details from "src/pages/Leaver/Details";
import StateHistory from "src/pages/Leaver/StateHistory";
import { useLeaverQuery } from "src/queries";

interface IProps {
  leaverId: number;
}

const Leaver = ({ leaverId }: IProps) => {
  const { data: leaver } = useLeaverQuery(leaverId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={
            leaver === undefined || leaver.state === LeaverState.ACTIONED
          }
          to={`/leavers/${leaverId}/action/`}
        >
          Action
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          { to: "/leavers/", label: "Leavers" },
          { label: leaver?.username ?? "" },
        ]}
        title={`Leaver ${leaver?.username ?? ""}`}
      />
      <Stack spacing={2}>
        <Details leaver={leaver} />
        <StateHistory leaverId={leaverId} />
      </Stack>
    </>
  );
};

export default Leaver;
