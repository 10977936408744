import { SkeletonRow } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useSystemMissingTitlesQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const MissingTitles = ({ systemId }: IProps) => {
  const { data: missingTitles } = useSystemMissingTitlesQuery(systemId);

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {missingTitles?.usernames.map((username) => (
              <TableRow key={username}>
                <TableCell>{username}</TableCell>
              </TableRow>
            )) ?? <SkeletonRow cols={1} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MissingTitles;
