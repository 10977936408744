import { Title } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Link as WLink } from "wouter";

import { MoverState } from "src/models";
import Details from "src/pages/Mover/Details";
import StateHistory from "src/pages/Mover/StateHistory";
import { useMoverQuery } from "src/queries";

interface IProps {
  moverId: number;
}

const Mover = ({ moverId }: IProps) => {
  const { data: mover } = useMoverQuery(moverId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={mover === undefined || mover.state === MoverState.ACTIONED}
          to={`/movers/${moverId}/action/`}
        >
          Action
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        breadcrumbs={[
          { to: "/movers/", label: "Movers" },
          { label: mover?.username ?? "" },
        ]}
        title={`Mover ${mover?.username ?? ""}`}
      />
      <Stack spacing={2}>
        <Details mover={mover} />
        <StateHistory moverId={moverId} />
      </Stack>
    </>
  );
};

export default Mover;
