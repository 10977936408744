import { z } from "zod";

const userAccessApprovalSchema = z.object({
  id: z.number().int().positive(),
  approved: z.boolean().nullable(),
  approver: z.string().nullable(),
  initialRoles: z.array(z.string()).min(1),
  notes: z.string(),
  roles: z.array(z.string()).nullable(),
  serviceAccount: z.boolean().nullable(),
  title: z.string().nullable(),
  username: z.string(),
});

export type UserAccessApproval = z.infer<typeof userAccessApprovalSchema>;

export const newUserAccessApproval = (data: unknown): UserAccessApproval =>
  userAccessApprovalSchema.parse(data);
