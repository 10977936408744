import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  Value,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { useActionLeaverMutation, useLeaverQuery } from "src/queries";

const FormSchema = z.object({
  reason: z.string().trim().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  leaverId: number;
}

const ActionLeaver = ({ leaverId }: IProps) => {
  const { data: leaver } = useLeaverQuery(leaverId);
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useActionLeaverMutation(leaverId);
  const methods = useForm<FormType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create(data);
      addToast("Actioned", "success");
      setLocation(`/leavers/${leaverId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Action leaver" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <Typography gutterBottom>
                Record that the leaver <Value text={leaver?.username} /> has
                been removed from all systems below.
              </Typography>
              <List>
                <ListItem key={-1}>
                  <ListItemText primary="Azure AD" />
                </ListItem>
                {leaver?.systems.map((system) => (
                  <ListItem key={system.systemId}>
                    <ListItemText primary={system.name} />
                  </ListItem>
                ))}
              </List>
              <TextField
                fullWidth
                helperText={`Please explain how you actioned this request to an auditor`}
                label="Reason for request"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Action" />
              <FormLinks
                links={[{ label: "Back", to: `/leavers/${leaverId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default ActionLeaver;
