import { z } from "zod";

export enum MoverState {
  CREATED = "CREATED",
  ACTIONED = "ACTIONED",
}

const systemSchema = z.object({
  systemId: z.number().int().positive(),
  name: z.string(),
});

const moverSchema = z.object({
  id: z.number().int().positive(),
  username: z.string(),
  moveDate: z.coerce.date(),
  state: z.nativeEnum(MoverState),
  systems: z.array(systemSchema),
  timestamp: z.coerce.date(),
});

export type Mover = z.infer<typeof moverSchema>;

export const newMover = (data: unknown): Mover => moverSchema.parse(data);
