import {
  sortByKey,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  useSystemTestUALQuery,
  useSystemUsernameMappingsQuery,
} from "src/queries";

interface IProps {
  systemId: number;
}

const TestUserAccessList = ({ systemId }: IProps) => {
  const { data: testUAL } = useSystemTestUALQuery(systemId);
  const { data: mappings } = useSystemUsernameMappingsQuery(systemId);
  const mapping =
    mappings?.reduce((accum: Record<string, string>, userMapping) => {
      accum[userMapping.username] = userMapping.email;
      return accum;
    }, {}) ?? {};

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: `/systems/${systemId}/`,
            label: testUAL?.systemName ?? "",
          },
          { label: "Test UAL" },
        ]}
        title="Test User Access List"
      />
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Mapped username</TableCell>
                <TableCell>Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testUAL?.users
                .sort(sortByKey((user) => [user.username], "asc"))
                .map((user) => (
                  <TableRow key={user.username}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{mapping[user.username]}</TableCell>
                    <TableCell>{user.roles.join(",")}</TableCell>
                  </TableRow>
                )) ?? <SkeletonRow cols={3} />}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell align="right">Total users</TableCell>
                <TableCell>{testUAL?.users.length}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default TestUserAccessList;
