import { z } from "zod";

const missingTitlesSchema = z.object({
  usernames: z.array(z.string()),
});

export type MissingTitles = z.infer<typeof missingTitlesSchema>;

export const newMissingTitles = (data: unknown): MissingTitles =>
  missingTitlesSchema.parse(data);
