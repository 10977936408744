import {
  QueryKey,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios from "axios";

import {
  Staff,
  StaffRole,
  StaffRoles,
  newStaff,
  newStaffRoles,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export type StaffMapping = { [key: string]: Staff };

export const useAllStaffQuery = () =>
  useQuery<StaffMapping>({
    queryKey: ["staff"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/staff/", { signal });
      return response.data.staff.reduce(
        (accum: StaffMapping, data: unknown) => {
          const member = newStaff(data);
          accum[member.id.toString()] = member;
          return accum;
        },
        {},
      );
    },
  });

export const useStaffQuery = (
  id: number | undefined,
  options?: Omit<
    UseQueryOptions<any, any, Staff, QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  const queryClient = useQueryClient();
  return useQuery<Staff>({
    ...options,
    queryKey: ["staff", id?.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/staff/${id}/`, { signal });
      return newStaff(response.data);
    },
    enabled: id !== undefined,
    initialData: () =>
      id !== undefined
        ? queryClient.getQueryData<StaffMapping>(["staff"])?.[id]
        : undefined,
  });
};

export const useStaffRolesQuery = (
  id: number | undefined,
  options?: Omit<
    UseQueryOptions<any, any, StaffRoles[], QueryKey>,
    "queryKey" | "queryFn"
  >,
) => {
  return useQuery<StaffRoles[]>({
    ...options,
    queryKey: ["staff", id?.toString(), "roles"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/staff-roles/${id}/`, { signal });
      return response.data.roles.map((data: unknown) => newStaffRoles(data));
    },
    enabled: id !== undefined,
  });
};

export interface IStaffRoleData {
  roles: StaffRole[];
  reason: string;
  systems: number[];
}

export const useUpdateStaffRolesMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IStaffRoleData) =>
      await axios.put(`/v1/staff/${id}/roles/`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["staff"] }),
  });
};

export interface IStaffDisabledData {
  disabled: boolean;
}

export const useDisableStaffMutation = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IStaffDisabledData) =>
      await axios.put(`/v1/staff/${id}/disabled/`, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["staff"] }),
  });
};
