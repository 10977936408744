import { z } from "zod";

export enum ReviewState {
  SETUP = "SETUP",
  OPEN = "OPEN",
  ADJUSTMENT = "ADJUSTMENT",
  CONFIRMATION = "CONFIRMATION",
  COMPLETE = "COMPLETE",
}

const userAccessReviewSchema = z.object({
  id: z.number().int().positive(),
  finalUalId: z.number().int().positive().nullable(),
  initialUalId: z.number().int().positive().nullable(),
  state: z.nativeEnum(ReviewState),
  systemId: z.number().int().positive(),
  systemName: z.string(),
  timestamp: z.coerce.date(),
});

export type UserAccessReview = z.infer<typeof userAccessReviewSchema>;

export const newUserAccessReview = (data: unknown): UserAccessReview =>
  userAccessReviewSchema.parse(data);
