import {
  CheckboxField,
  TextField,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { UserAccessApproval } from "src/models";
import { useCreateUserAccessApprovalMutation } from "src/queries";

const FormSchema = z.object({
  approved: z.boolean(),
  notes: z.string(),
  roles: z.string(),
  serviceAccount: z.boolean(),
});
type FormType = z.infer<typeof FormSchema>;

interface IProps {
  approval: UserAccessApproval;
}

const ApprovalItem = ({ approval }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateUserAccessApprovalMutation();

  const methods = useForm({
    defaultValues: {
      approved: approval.approved === true,
      notes: approval.notes,
      roles: approval.roles?.join(",") ?? approval.initialRoles.join(","),
      serviceAccount: approval.serviceAccount === true,
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: FormType, approved: boolean) => {
    try {
      await create({
        approved,
        notes: data.notes ?? "",
        roles: data.roles.split(",").map((role) => role.trim()),
        serviceAccount: data.serviceAccount,
        userAccessId: approval.id,
      });
      addToast(approved ? "Approved" : "Disapproved", "success");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          addToast("No access to this system", "error");
        } else if (error.response?.status === 409) {
          addToast("You cannot review your own access", "error");
        } else {
          addToast("Try again", "error");
        }
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <ListItem
          secondaryAction={
            <ButtonGroup>
              <IconButton
                aria-label="approve"
                color={approval.approved === true ? "primary" : undefined}
                onClick={() =>
                  methods.handleSubmit((data) => onSubmit(data, true))()
                }
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                aria-label="approve"
                color={approval.approved === false ? "primary" : undefined}
                edge="end"
                onClick={() =>
                  methods.handleSubmit((data) => onSubmit(data, false))()
                }
              >
                <CloseIcon />
              </IconButton>
            </ButtonGroup>
          }
          sx={{ paddingRight: 12 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="body1">
              {approval.username}{" "}
              {approval.title !== null ? `(${approval.title})` : null}
            </Typography>
            <Box sx={{ paddingLeft: 2 }}>
              <TextField
                fullWidth
                helperText="Comma seperated roles please"
                label="Roles"
                name="roles"
                autoComplete="off"
                required
                size="small"
              />
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                autoComplete="off"
                size="small"
              />
              <CheckboxField
                fullWidth
                label="Service account?"
                name="serviceAccount"
              />
            </Box>
          </Box>
        </ListItem>
        <Divider variant="middle" component="li" />
      </form>
    </FormProvider>
  );
};

export default ApprovalItem;
