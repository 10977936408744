import { ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import SystemForm, { ValidatedType } from "src/components/SystemForm";
import { AccessType, IntegrationType, UARType } from "src/models";
import { useEditSystemMutation, useSystemQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const EditSystem = ({ systemId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: system } = useSystemQuery(systemId);
  const { mutateAsync: edit } = useEditSystemMutation(systemId);

  const defaultValues = {
    accessType: system?.accessType ?? AccessType.MANUAL,
    description: system?.description ?? "",
    integration: system?.integration ?? IntegrationType.NONE,
    integrationArgs: "",
    multirole: system?.multirole ?? false,
    name: system?.name ?? "",
    requestableRoles: system?.requestableRoles.join(", ") ?? "",
    uarType: system?.uarType ?? UARType.MANUAL,
  };

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        ...data,
        integrationArgs:
          data.integrationArgs === ""
            ? undefined
            : JSON.parse(data.integrationArgs),
        requestableRoles: data.requestableRoles
          .split(",")
          .map((role) => role.trim()),
      });
      addToast("System updated", "success");
      setLocation(`/systems/${systemId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Card>
      <SystemForm
        back={`/systems/${systemId}/`}
        defaultValues={defaultValues}
        disabled={system === undefined}
        label="Edit System"
        onSubmit={onSubmit}
      />
    </Card>
  );
};

export default EditSystem;
