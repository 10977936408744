import { useHash, Title } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";
import { Link as WLink } from "wouter";

import RequestsTable from "src/pages/AccessRequests/RequestsTable";

type Tabs = "open" | "approved" | "complete";

const AccessRequests = () => {
  const [tab, setTab] = useHash<Tabs>("open");

  return (
    <>
      <Title
        actions={
          <Button
            component={WLink}
            to="/access-requests/new/"
            variant="contained"
          >
            New
          </Button>
        }
        title=" Requests"
      />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as Tabs)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Open" value="open" />
            <Tab label="Approved" value="approved" />
            <Tab label="Complete" value="complete" />
          </TabList>
        </TabContext>
        <Divider />
        <RequestsTable state={tab} />
      </Card>
    </>
  );
};

export default AccessRequests;
