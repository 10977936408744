import { useHash, Title } from "@curaleaf-international/components";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tab from "@mui/material/Tab";

import LeaversTable from "src/pages/Leavers/LeaversTable";

type Tabs = "open" | "actioned";

const Leavers = () => {
  const [tab, setTab] = useHash<Tabs>("open");

  return (
    <>
      <Title title="Leavers" />
      <Card>
        <TabContext value={tab}>
          <TabList
            onChange={(_, value: string) => setTab(value as Tabs)}
            sx={{ marginLeft: 3 }}
          >
            <Tab label="Open" value="open" />
            <Tab label="Actioned" value="actioned" />
          </TabList>
        </TabContext>
        <Divider />
        <LeaversTable state={tab} />
      </Card>
    </>
  );
};

export default Leavers;
