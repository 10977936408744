import { useQuery } from "@curaleaf-international/components";
import axios from "axios";

import { ParsedUser, newParsedUser } from "src/models";
import { newAllEndpointRoles, AllEndpointRoles } from "src/models/utils";
import { useMutation } from "src/query";

export const useParseUALCSVMutation = () =>
  useMutation<ParsedUser[], void, File[]>({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("files", file));
      const response = await axios.post(`/v1/utils/parse-ual-csv/`, formData);
      return response.data.users.map((data: unknown) => newParsedUser(data));
    },
  });

export const useEndpointRolesQuery = () =>
  useQuery<AllEndpointRoles>({
    queryKey: ["endpoint-roles"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/utils/endpoint-roles/", { signal });
      return newAllEndpointRoles(response.data);
    },
  });
