import { z } from "zod";

export enum StaffRole {
  ADMINISTRATOR = "ADMINISTRATOR",
  AUDITOR = "AUDITOR",
  APPROVER = "APPROVER",
  DEVELOPER = "DEVELOPER",
}

const staffSchema = z.object({
  id: z.number().int().positive(),
  activeSessions: z.number().int(),
  cezanneId: z.string().nullable(),
  created: z.coerce.date(),
  disabled: z.coerce.date().nullable(),
  email: z.string().email(),
  lastLogin: z.coerce.date().nullable(),
  microsoftId: z.string().nullable(),
  roles: z.array(z.nativeEnum(StaffRole)),
  systemIds: z.array(z.number()),
  systemNames: z.array(z.string()),
});

export type Staff = z.infer<typeof staffSchema>;

export const newStaff = (data: unknown): Staff => staffSchema.parse(data);

const staffRolesSchema = z.object({
  id: z.number().int().positive(),
  staffId: z.number().int().positive(),
  activeFrom: z.coerce.date(),
  expires: z.coerce.date(),
  roles: z.array(z.nativeEnum(StaffRole)),
  grantedBy: z.number().int().positive(),
  grantedByEmail: z.string(),
  reason: z.string(),
  systemIds: z.array(z.number()),
  systemNames: z.array(z.string()),
});

export type StaffRoles = z.infer<typeof staffRolesSchema>;

export const newStaffRoles = (data: unknown): StaffRoles =>
  staffRolesSchema.parse(data);
