import { CheckboxField } from "@curaleaf-international/components";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";

interface ICheckboxProps {
  label: string;
  name: string;
  required?: boolean;
}

interface IProps {
  label: string;
  options: ICheckboxProps[];
}

const MultiCheckbox = ({ label, options }: IProps) => (
  <>
    <FormLabel component="legend">{label}</FormLabel>
    <FormGroup>
      {options.map((option) => (
        <CheckboxField key={option.name} {...option} />
      ))}
    </FormGroup>
  </>
);

export default MultiCheckbox;
