import axios from "axios";

import { MissingTitles, newMissingTitles } from "src/models";
import { useQuery } from "src/query";

export const useSystemMissingTitlesQuery = (systemId: number) =>
  useQuery<MissingTitles>({
    queryKey: ["missingTitles", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/missing-titles/system/${systemId}/`,
        { signal },
      );
      return newMissingTitles(response.data);
    },
  });
