import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { AccessRequestState } from "src/models";
import { useEditAccessRequestMutation } from "src/queries";

const FormSchema = z.object({
  reason: z.string().trim().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  requestId: number;
  state:
    | AccessRequestState.ACTIONED
    | AccessRequestState.APPROVED
    | AccessRequestState.DENIED
    | AccessRequestState.WITHDRAWN;
}

const EditAccessRequest = ({ requestId, state }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: edit } = useEditAccessRequestMutation(requestId);
  const methods = useForm<FormType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        reason: data.reason,
        state,
      });
      addToast("Request updated", "success");
      setLocation(`/access-requests/${requestId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  let label = "Approve";
  if (state === AccessRequestState.DENIED) {
    label = "Deny";
  } else if (state === AccessRequestState.WITHDRAWN) {
    label = "Withdraw";
  } else if (state === AccessRequestState.ACTIONED) {
    label = "Action";
  }

  return (
    <>
      <Title title="Update access request" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                fullWidth
                helperText={`Please explain why you will ${label} this request to an auditor`}
                label="Reason for request"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label={label} />
              <FormLinks
                links={[
                  { label: "Back", to: `/access-requests/${requestId}/` },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditAccessRequest;
