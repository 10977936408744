import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { useCreateUsernameMappingMutation, useSystemQuery } from "src/queries";

const FormSchema = z.object({
  email: z.string().email().trim().min(1),
  username: z.string().trim().min(1),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  systemId: number;
}

const CreateUsernameMapping = ({ systemId }: IProps) => {
  const { data: system } = useSystemQuery(systemId);
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateUsernameMappingMutation();

  const methods = useForm<FormType>({
    defaultValues: {
      email: "",
      username: "",
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({ email: data.email, systemId, username: data.username });
      addToast("Mapping added", "success");
      setLocation(`/systems/${systemId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title
        breadcrumbs={[
          {
            to: `/systems/${systemId}/`,
            label: system?.name ?? "",
          },
          { label: "Add username mapping" },
        ]}
        title="Add username mapping"
      />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                autoComplete="off"
                fullWidth
                label="Email"
                name="email"
                type="email"
                required
              />
              <TextField
                autoComplete="off"
                fullWidth
                label="Username"
                name="username"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Add" />
              <FormLinks
                links={[{ label: "Back", to: `/systems/${systemId}/` }]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateUsernameMapping;
