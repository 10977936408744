import {
  APIError,
  FormLinks,
  Title,
  ToastContext,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext } from "react";
import { useLocation } from "wouter";

import { useCloseUserAccessReviewMutation } from "src/queries";

interface IProps {
  reviewId: number;
}

const CloseUserAccessReview = ({ reviewId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: close, isPending } =
    useCloseUserAccessReviewMutation(reviewId);

  const onClick = async () => {
    try {
      await close();
      addToast("Review closed", "success");
      setLocation(`/user-access-reviews/${reviewId}/`);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if ((error.response?.data as APIError).code === "UNREVIEWED_ACCESS") {
          addToast("Not all users have been reviewed", "error");
        } else if ((error.response?.data as APIError).code === "REVIEW_OPEN") {
          addToast("Review is not open", "error");
        } else {
          addToast("Try again", "error");
        }
      } else {
        addToast("Try again", "error");
      }
    }
  };

  return (
    <>
      <Title title="Close review" />
      <Card>
        <CardContent>
          Close the review, and thereby prevent any more approvals.
        </CardContent>
        <Divider />
        <CardActions>
          <LoadingButton
            color="primary"
            loading={isPending}
            onClick={onClick}
            variant="contained"
          >
            Close
          </LoadingButton>
          <FormLinks
            links={[{ label: "Back", to: `/user-access-reviews/${reviewId}/` }]}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default CloseUserAccessReview;
