import {
  formatDate,
  formatDateTime,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { Leaver, LeaverState } from "src/models";
import { useLeaversQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "terminationDate" | "timestamp" | "username";

interface IProps {
  state: "open" | "actioned";
}

const Leavers = ({ state }: IProps) => {
  const { data: leavers } = useLeaversQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] =
    useState<OrderableProperties>("terminationDate");

  const sortKey = (leaver: Leaver) => [leaver[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={2} />;

  if (leavers !== undefined && leavers.length > 0) {
    rows = leavers
      .filter(
        (leaver) =>
          (state === "open" && leaver.state !== LeaverState.ACTIONED) ||
          (state === "actioned" && leaver.state === LeaverState.ACTIONED),
      )
      .sort(sortByKey(sortKey, order))
      .map((leaver) => (
        <TableRow key={leaver.id}>
          <TableCell>
            <Link component={WLink} to={`/leavers/${leaver.id}/`}>
              {leaver.username}
            </Link>
          </TableCell>
          <TableCell>{formatDate(leaver.terminationDate)}</TableCell>
          <TableCell>{leaver.systems.length}</TableCell>
          <TableCell>
            {leaver.state}
            <br />
            {formatDateTime(leaver.timestamp)}
          </TableCell>
        </TableRow>
      ));
  } else if (leavers !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={4}>No leavers found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "username"}
                direction={order}
                onClick={onSortClick("username")}
              >
                Username
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "terminationDate"}
                direction={order}
                onClick={onSortClick("terminationDate")}
              >
                Termination date
              </TableSortLabel>
            </TableCell>
            <TableCell>Systems</TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "timestamp"}
                direction={order}
                onClick={onSortClick("timestamp")}
              >
                State
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default Leavers;
