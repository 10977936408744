import { sortByKey, Title } from "@curaleaf-international/components";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { StaffRole, ReviewState } from "src/models";
import ApprovalForm from "src/pages/UserAccessApprovals/ApprovalForm";
import ApprovalItem from "src/pages/UserAccessApprovals/ApprovalItem";
import {
  useUserAccessApprovalsQuery,
  useUserAccessReviewQuery,
} from "src/queries";

interface IProps {
  reviewId: number;
}

const UserAccessApprovals = ({ reviewId }: IProps) => {
  const { member } = useContext(AuthContext);
  const { data: list } = useUserAccessApprovalsQuery(reviewId);
  const { data: review } = useUserAccessReviewQuery(reviewId);

  return (
    <>
      <Title
        breadcrumbs={
          member?.roles.some((role) =>
            [StaffRole.ADMINISTRATOR, StaffRole.DEVELOPER].includes(role),
          )
            ? [
                {
                  to: `/systems/${review?.systemId}/`,
                  label: review?.systemName ?? "",
                },
                {
                  to: `/user-access-reviews/${review?.id}/`,
                  label: `User Access Review ${review?.id}`,
                },
                { label: "User Access Approvals" },
              ]
            : undefined
        }
        title={`${review?.systemName ?? ""} User Access Approvals`}
      />
      <Card>
        <CardContent>
          <Typography gutterBottom variant="body2">
            Please only approve (
            <CheckIcon
              sx={{ fontSize: "inherit", verticalAlign: "text-top" }}
            />
            ) or disapprove (
            <CloseIcon
              sx={{ fontSize: "inherit", verticalAlign: "text-top" }}
            />
            ) those users who report to you.
          </Typography>
          <Typography variant="body2">
            Service accounts are user accounts that are not used by humans but
            rather used by technical integrations and systems.
          </Typography>
          <List>
            {list
              ?.sort(sortByKey((user) => [user.approved, user.username], "asc"))
              .map((user) => {
                if (review === undefined) {
                  return (
                    <ListItem key={`${user.id}-skeleton}`}>
                      <Skeleton height="60px" width="100%" />
                    </ListItem>
                  );
                } else if (
                  user.approved === null &&
                  review?.state === ReviewState.OPEN
                ) {
                  return (
                    <ApprovalForm key={`${user.id}-form}`} approval={user} />
                  );
                } else {
                  return (
                    <ApprovalItem key={`${user.id}-item}`} approval={user} />
                  );
                }
              })}
            <ListItem>
              <ListItemText primary={`Total users ${list?.length}`} />
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default UserAccessApprovals;
