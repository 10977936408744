import {
  formatDate,
  sortByKey,
  SkeletonRow,
  Title,
} from "@curaleaf-international/components";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { System } from "src/models";
import { useSystemsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties =
  | "accessType"
  | "lastUarTimestamp"
  | "lastUarState"
  | "name"
  | "uarType";

const Systems = () => {
  const { data: systems } = useSystemsQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("name");

  const sortKey = (system: System) => [system[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={5} />;

  if (systems !== undefined && systems.length > 0) {
    rows = systems.sort(sortByKey(sortKey, order)).map((system) => (
      <TableRow key={system.id}>
        <TableCell>
          <Link component={WLink} to={`/systems/${system.id}/`}>
            {system.name}
          </Link>
        </TableCell>
        <TableCell>{system.uarType}</TableCell>
        <TableCell>{system.accessType}</TableCell>
        <TableCell>
          {system.lastUarTimestamp !== null
            ? formatDate(system.lastUarTimestamp)
            : "No UARs"}
        </TableCell>
        <TableCell>{system.lastUarState ?? "No UARs"}</TableCell>
      </TableRow>
    ));
  } else if (systems !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={5}>No systems found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Title
        actions={
          <Button component={WLink} to="/systems/new/" variant="contained">
            New
          </Button>
        }
        title="Systems"
      />
      <Card>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "name"}
                    direction={order}
                    onClick={onSortClick("name")}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "uarType"}
                    direction={order}
                    onClick={onSortClick("uarType")}
                  >
                    UAR Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "accessType"}
                    direction={order}
                    onClick={onSortClick("accessType")}
                  >
                    Access Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "lastUarTimestamp"}
                    direction={order}
                    onClick={onSortClick("lastUarTimestamp")}
                  >
                    Latest UAR
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === "lastUarState"}
                    direction={order}
                    onClick={onSortClick("lastUarState")}
                  >
                    Latest UAR State
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default Systems;
