import { z } from "zod";

const usernameMappingSchema = z.object({
  id: z.number().int().positive(),
  email: z.string(),
  systemId: z.number().int().positive(),
  username: z.string(),
});

export type UsernameMapping = z.infer<typeof usernameMappingSchema>;

export const newUsernameMapping = (data: unknown): UsernameMapping =>
  usernameMappingSchema.parse(data);
