import { z } from "zod";

const userAccessSchema = z.object({
  id: z.number().int().positive(),
  roles: z.array(z.string()).min(1),
  title: z.string().nullable(),
  username: z.string(),
});

export type UserAccess = z.infer<typeof userAccessSchema>;

export const newUserAccess = (data: unknown): UserAccess =>
  userAccessSchema.parse(data);

export enum UALSource {
  AUTOMATED = "AUTOMATED",
  MANUAL = "MANUAL",
}

const userAccessListSchema = z.object({
  id: z.number().int().positive(),
  notes: z.string().nullable(),
  reviewId: z.number().int().positive(),
  source: z.nativeEnum(UALSource),
  systemId: z.number().int().positive(),
  systemName: z.string(),
  timestamp: z.coerce.date(),
  users: z.array(userAccessSchema),
});

export type UserAccessList = z.infer<typeof userAccessListSchema>;

export const newUserAccessList = (data: unknown): UserAccessList =>
  userAccessListSchema.parse(data);
