import { z } from "zod";

import { AccessRequestState } from "src/models/accessRequest";

const accessRequestStateHistorySchema = z.object({
  id: z.number().int().positive(),
  accessRequestId: z.number().int().positive(),
  reason: z.string(),
  staffEmail: z.string(),
  staffId: z.number().int().positive(),
  state: z.nativeEnum(AccessRequestState),
  timestamp: z.coerce.date(),
});

export type AccessRequestStateHistory = z.infer<
  typeof accessRequestStateHistorySchema
>;

export const newAccessRequestStateHistory = (
  data: unknown,
): AccessRequestStateHistory => accessRequestStateHistorySchema.parse(data);
