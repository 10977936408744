import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { UserAccessList, newUserAccessList } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useUserAccessListQuery = (listId: number) =>
  useQuery<UserAccessList>({
    queryKey: ["userAccessLists", listId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/user-access-lists/${listId}/`, {
        signal,
      });
      return newUserAccessList(response.data);
    },
  });

interface IListData {
  roles: string[];
  username: string;
}

export const useCreateUserAccessListMutation = (reviewId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (users: IListData[]) =>
      await axios.post("/v1/user-access-lists/", { reviewId, users }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userAccessLists"] });
      queryClient.invalidateQueries({ queryKey: ["userAccessReviews"] });
      queryClient.invalidateQueries({ queryKey: ["userAccessApprovals"] });
    },
  });
};

export const useSetUserAccessListNotes = (listId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (notes: string) =>
      await axios.put(`/v1/user-access-lists/${listId}/notes/`, {
        notes: notes.length > 0 ? notes : null,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userAccessLists"] });
    },
  });
};
