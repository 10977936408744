import { useContext } from "react";
import { Redirect } from "wouter";

import { AuthContext } from "src/AuthContext";
import { StaffRole } from "src/models";

const Homepage = () => {
  const { member } = useContext(AuthContext);

  if (member!.roles.includes(StaffRole.ADMINISTRATOR)) {
    return <Redirect to="/systems/" />;
  } else if (member!.roles.length === 0) {
    return <Redirect to="/access-requests/new/" />;
  } else {
    return <Redirect to="/user-access-reviews/open/" />;
  }
};

export default Homepage;
