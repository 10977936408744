import { z } from "zod";

import { ReviewState } from "src/models/userAccessReview";

export enum AccessType {
  BUILTIN = "BUILTIN",
  EXEMPT = "EXEMPT",
  MANUAL = "MANUAL",
  OTHER = "OTHER",
}

export enum IntegrationType {
  NONE = "NONE",
  AWS = "AWS",
  BITWARDEN = "BITWARDEN",
  CEZANNE = "CEZANNE",
  GITHUB = "GITHUB",
}

export enum UARType {
  BUILTIN = "BUILTIN",
  EXEMPT = "EXEMPT",
  INTEGRATED = "INTEGRATED",
  MANUAL = "MANUAL",
}

const systemSchema = z.object({
  id: z.number().int().positive(),
  accessType: z.nativeEnum(AccessType),
  description: z.string(),
  integration: z.nativeEnum(IntegrationType),
  lastUarState: z.nativeEnum(ReviewState).nullable(),
  lastUarTimestamp: z.coerce.date().nullable(),
  multirole: z.boolean(),
  name: z.string(),
  requestableRoles: z.array(z.string()),
  uarType: z.nativeEnum(UARType),
});

export type System = z.infer<typeof systemSchema>;

export const newSystem = (data: unknown): System => systemSchema.parse(data);

const testUserSchema = z.object({
  roles: z.array(z.string()).min(1),
  username: z.string(),
});

export type TestUser = z.infer<typeof testUserSchema>;

const testUALSchema = z.object({
  systemName: z.string(),
  users: z.array(testUserSchema),
});

export type TestUAL = z.infer<typeof testUALSchema>;

export const newTestUAL = (data: unknown): TestUAL => testUALSchema.parse(data);
