import {
  formatDateTime,
  sortByKey,
  SkeletonRow,
} from "@curaleaf-international/components";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useState, ReactNode } from "react";
import { Link as WLink } from "wouter";

import { ReviewState, UserAccessReview } from "src/models";
import { useUserAccessReviewsQuery } from "src/queries";

type Direction = "asc" | "desc";
type OrderableProperties = "systemName" | "timestamp";

interface IProps {
  state: "open" | "complete";
}

const ReviewsTable = ({ state }: IProps) => {
  const { data: reviews } = useUserAccessReviewsQuery();
  const [order, setOrder] = useState<Direction>("asc");
  const [orderBy, setOrderBy] = useState<OrderableProperties>("systemName");

  const sortKey = (review: UserAccessReview) => [review[orderBy]];

  let rows: ReactNode | ReactNode[] = <SkeletonRow cols={2} />;

  if (reviews !== undefined && reviews.length > 0) {
    rows = reviews
      .filter(
        (review) =>
          (state === "open" && review.state !== ReviewState.COMPLETE) ||
          (state === "complete" && review.state === ReviewState.COMPLETE),
      )
      .sort(sortByKey(sortKey, order))
      .map((review) => (
        <TableRow key={review.id}>
          <TableCell>
            <Link
              component={WLink}
              to={`/user-access-reviews/${review.id}/approvals/`}
            >
              {review.systemName}
            </Link>
          </TableCell>
          <TableCell>
            {review.state}
            <br />
            {formatDateTime(review.timestamp)}
          </TableCell>
        </TableRow>
      ));
  } else if (reviews !== undefined) {
    rows = (
      <TableRow>
        <TableCell colSpan={2}>No open reviews found</TableCell>
      </TableRow>
    );
  }

  const onSortClick = (property: OrderableProperties) => () => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={orderBy === "systemName"}
                direction={order}
                onClick={onSortClick("systemName")}
              >
                System
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === "timestamp"}
                direction={order}
                onClick={onSortClick("timestamp")}
              >
                State
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewsTable;
