import { Title } from "@curaleaf-international/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useContext, useState } from "react";
import { Link as WLink } from "wouter";

import { AuthContext } from "src/AuthContext";
import { AccessRequestState } from "src/models";
import Details from "src/pages/AccessRequest/Details";
import StateHistory from "src/pages/AccessRequest/StateHistory";
import { useAccessRequestQuery } from "src/queries";

interface IProps {
  requestId: number;
}

const AccessRequest = ({ requestId }: IProps) => {
  const { member } = useContext(AuthContext);
  const { data: request } = useAccessRequestQuery(requestId);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Menu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} open={open}>
        <MenuItem
          component={WLink}
          disabled={
            request === undefined ||
            request.state !== AccessRequestState.REQUESTED
          }
          to={`/access-requests/${requestId}/approve/`}
        >
          Approve
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            request === undefined ||
            ![
              AccessRequestState.REQUESTED,
              AccessRequestState.APPROVED,
            ].includes(request.state)
          }
          to={`/access-requests/${requestId}/deny/`}
        >
          Deny
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            request === undefined ||
            request.state !== AccessRequestState.REQUESTED ||
            request.ownerId != member!.id
          }
          to={`/access-requests/${requestId}/withdraw/`}
        >
          Withdraw
        </MenuItem>
        <MenuItem
          component={WLink}
          disabled={
            request === undefined ||
            request.state !== AccessRequestState.APPROVED
          }
          to={`/access-requests/${requestId}/action/`}
        >
          Action
        </MenuItem>
      </Menu>
      <Title
        actions={
          <Button
            endIcon={<KeyboardArrowDownIcon />}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            Actions
          </Button>
        }
        title={`Request for access to ${request?.systemName ?? ""}`}
      />
      <Stack spacing={2}>
        <Details request={request} />
        <StateHistory requestId={requestId} />
      </Stack>
    </>
  );
};

export default AccessRequest;
