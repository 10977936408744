import { z } from "zod";

import { MoverState } from "src/models/mover";

const moverStateHistorySchema = z.object({
  id: z.number().int().positive(),
  moverId: z.number().int().positive(),
  reason: z.string().nullable(),
  staffEmail: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  state: z.nativeEnum(MoverState),
  timestamp: z.coerce.date(),
});

export type MoverStateHistory = z.infer<typeof moverStateHistorySchema>;

export const newMoverStateHistory = (data: unknown): MoverStateHistory =>
  moverStateHistorySchema.parse(data);
