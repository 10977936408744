import axios from "axios";

import { MoverStateHistory, newMoverStateHistory } from "src/models";
import { useQuery } from "src/query";

export const useMoverStateHistoryQuery = (moverId: number) => {
  return useQuery<MoverStateHistory[]>({
    queryKey: ["movers", moverId, "history"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/movers/${moverId}/state-history/`, {
        signal,
      });
      return response.data.history.map((data: unknown) =>
        newMoverStateHistory(data),
      );
    },
  });
};
