import axios from "axios";

import { LeaverStateHistory, newLeaverStateHistory } from "src/models";
import { useQuery } from "src/query";

export const useLeaverStateHistoryQuery = (leaverId: number) => {
  return useQuery<LeaverStateHistory[]>({
    queryKey: ["leavers", leaverId, "history"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/leavers/${leaverId}/state-history/`,
        { signal },
      );
      return response.data.history.map((data: unknown) =>
        newLeaverStateHistory(data),
      );
    },
  });
};
