import { z } from "zod";

export enum AccessRequestState {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  ACTIONED = "ACTIONED",
  DENIED = "DENIED",
  WITHDRAWN = "WITHDRAWN",
}

const accessRequestSchema = z.object({
  id: z.number().int().positive(),
  ownerEmail: z.string(),
  ownerId: z.number().int().positive(),
  roles: z.array(z.string()).min(1),
  state: z.nativeEnum(AccessRequestState),
  systemId: z.number().int().positive(),
  systemName: z.string(),
  timestamp: z.coerce.date(),
  username: z.string(),
});

export type AccessRequest = z.infer<typeof accessRequestSchema>;

export const newAccessRequest = (data: unknown): AccessRequest =>
  accessRequestSchema.parse(data);
