import { z } from "zod";

import { StaffRole } from "src/models/staff";

const parsedUserSchema = z.object({
  roles: z.array(z.string()).min(1),
  username: z.string(),
});

export type ParsedUser = z.infer<typeof parsedUserSchema>;

export const newParsedUser = (data: unknown): ParsedUser =>
  parsedUserSchema.parse(data);

const allEndpointRolesSchema = z.object({
  allRoles: z.array(z.nativeEnum(StaffRole)),
  endpointRoles: z.array(
    z.object({
      endpoint: z.string(),
      accessibleTo: z.array(z.nativeEnum(StaffRole)),
    }),
  ),
});

export type AllEndpointRoles = z.infer<typeof allEndpointRolesSchema>;
export const newAllEndpointRoles = (data: unknown): AllEndpointRoles =>
  allEndpointRolesSchema.parse(data);
