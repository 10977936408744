import { z } from "zod";

const userAccessListEvidenceSchema = z.object({
  id: z.number().int().positive(),
  name: z.string(),
  timestamp: z.coerce.date(),
  uploaderEmail: z.string(),
  uploaderId: z.number().int().positive(),
});

export type UserAccessListEvidence = z.infer<
  typeof userAccessListEvidenceSchema
>;

export const newUserAccessListEvidence = (
  data: unknown,
): UserAccessListEvidence => userAccessListEvidenceSchema.parse(data);
