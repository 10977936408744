import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { UserAccessListEvidence, newUserAccessListEvidence } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useUserAccessListEvidenceQuery = (listId: number) =>
  useQuery<UserAccessListEvidence[]>({
    queryKey: ["userAccessListEvidences", listId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/user-access-lists/${listId}/evidences/`,
        { signal },
      );
      return response.data.evidences.map((evidence: unknown) =>
        newUserAccessListEvidence(evidence),
      );
    },
  });

export const useCreateUserAccessListEvidenceMutation = (listId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (fileList: File[]) => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("file", file));
      await axios.post(`/v1/user-access-lists/${listId}/evidences/`, formData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userAccessListEvidences", listId.toString()],
      });
    },
  });
};
