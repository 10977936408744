import { formatDateTime } from "@curaleaf-international/components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useStaffRolesQuery } from "src/queries";

interface IProps {
  staffId: number;
}

const RoleHistory = ({ staffId }: IProps) => {
  const { data: staffRoles } = useStaffRolesQuery(staffId);

  let roleHistory;
  if (staffRoles !== undefined) {
    roleHistory = Object.values(staffRoles);
  }

  const currentDateTime = new Date();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Roles</TableCell>
            <TableCell>Approval Systems</TableCell>
            <TableCell>Active From</TableCell>
            <TableCell>Active To</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Granted By</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roleHistory?.map((role) => (
            <TableRow key={role.id}>
              <TableCell>
                {role.roles.join(", ")}
                {role.roles.length === 0 ? "NO ASSIGNED ROLES" : ""}
                {role.expires > currentDateTime ? <b> (CURRENT) </b> : ""}
              </TableCell>
              <TableCell>
                {role.systemNames.length === 0
                  ? "NO ASSIGNED SYSTEMS"
                  : role.systemNames.join(", ")}
              </TableCell>
              <TableCell>{formatDateTime(role.activeFrom)}</TableCell>
              <TableCell>{formatDateTime(role.expires)}</TableCell>
              <TableCell>{role.reason}</TableCell>
              <TableCell>{role.grantedByEmail}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RoleHistory;
