import { z } from "zod";

import { LeaverState } from "src/models/leaver";

const leaverStateHistorySchema = z.object({
  id: z.number().int().positive(),
  leaverId: z.number().int().positive(),
  reason: z.string().nullable(),
  staffEmail: z.string().nullable(),
  staffId: z.number().int().positive().nullable(),
  state: z.nativeEnum(LeaverState),
  timestamp: z.coerce.date(),
});

export type LeaverStateHistory = z.infer<typeof leaverStateHistorySchema>;

export const newLeaverStateHistory = (data: unknown): LeaverStateHistory =>
  leaverStateHistorySchema.parse(data);
