import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import {
  AccessRequest,
  AccessRequestState,
  newAccessRequest,
} from "src/models";
import { useMutation, useQuery } from "src/query";

export const useAccessRequestQuery = (requestId: number) =>
  useQuery<AccessRequest>({
    queryKey: ["accessRequests", requestId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(`/v1/access-requests/${requestId}/`, {
        signal,
      });
      return newAccessRequest(response.data);
    },
  });

export const useAccessRequestsQuery = () =>
  useQuery<AccessRequest[]>({
    queryKey: ["accessRequests"],
    queryFn: async ({ signal }) => {
      const response = await axios.get("/v1/access-requests/", {
        signal,
      });
      return response.data.accessRequests.map((data: unknown) =>
        newAccessRequest(data),
      );
    },
  });

export const useSystemAccessRequestsQuery = (systemId: number) =>
  useQuery<AccessRequest[]>({
    queryKey: ["accessRequests", "system", systemId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/access-requests/system/${systemId}/`,
        { signal },
      );
      return response.data.accessRequests.map((data: unknown) =>
        newAccessRequest(data),
      );
    },
  });

interface IAccessRequest {
  reason: string;
  roles: string[];
  systemId: number;
  username: string;
}

export const useCreateAccessRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IAccessRequest) =>
      await axios.post("/v1/access-requests/", data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accessRequests"] });
    },
  });
};

interface IAccessRequestState {
  reason: string;
  state: AccessRequestState;
}

export const useEditAccessRequestMutation = (requestId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IAccessRequestState) =>
      await axios.post(`/v1/access-requests/${requestId}/state/`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accessRequests"] });
    },
  });
};
