import {
  sortByKey,
  SkeletonRow,
  formatDateTime,
} from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link as WLink } from "wouter";

import { useAccessRequestStateHistoryQuery } from "src/queries";

interface IProps {
  requestId: number;
}

const AccessRequestStateHistory = ({ requestId }: IProps) => {
  const { data: history } = useAccessRequestStateHistoryQuery(requestId);
  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Updated By</TableCell>
              <TableCell>Update Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history
              ?.sort(sortByKey((record) => [record.timestamp], "desc"))
              .map((record) => (
                <TableRow key={record.id}>
                  <TableCell>{record.state}</TableCell>
                  <TableCell>{formatDateTime(record.timestamp)}</TableCell>
                  <TableCell>
                    <Link component={WLink} to={`/staff/${record.staffId}/`}>
                      {record.staffEmail}
                    </Link>
                  </TableCell>
                  <TableCell>{record.reason}</TableCell>
                </TableRow>
              )) ?? <SkeletonRow cols={4} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default AccessRequestStateHistory;
