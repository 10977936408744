import axios from "axios";

import {
  AccessRequestStateHistory,
  newAccessRequestStateHistory,
} from "src/models";
import { useQuery } from "src/query";

export const useAccessRequestStateHistoryQuery = (accessRequestId: number) => {
  return useQuery<AccessRequestStateHistory[]>({
    queryKey: ["accessRequests", accessRequestId, "history"],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/access-requests/${accessRequestId}/state-history/`,
        { signal },
      );
      return response.data.history.map((data: unknown) =>
        newAccessRequestStateHistory(data),
      );
    },
  });
};
